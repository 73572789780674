module.exports = {
    banner1_text: 'La vita è troppo breve per trascorrere tempo con persone che risucchiano la tua felicità. Se qualcuno ti vuole nella sua vita, troverà spazio per te.',
    leistungsumfang: 'Ambito dei servizi',
    leistungsumfang_text: 'Attualmente serviamo oltre 100.000 clienti e riceviamo buone recensioni',
    leistungsumfang_free: 'TRASFERIMENTO GRATUITO',
    home: 'Home',
    about: 'Chi siamo',
    order: 'Ordine',
    record: 'Storico ordini',
    user: 'Mio',
    about_text1: 'Prodotti di qualità',
    about_text2: 'Più prodotti',
    order_title: 'Ordine di oggi',
    zongzichan: 'Attivi totali',
    jinridan: 'Numero di ordini oggi',
    tiyanjin: 'Fondo esperienza',
    jinrishou: 'Guadagno di oggi',
    jintuandui: 'Commissione del team oggi',
    dianjigou: 'Avvia compito',
    adianjigou: 'Invia',
    order_illustrate: 'La piattaforma non è responsabile se i soldi vengono trasferiti erroneamente sul tuo conto bancario. L’importo minimo per il prelievo è di 50.000 won.',
    order_record: 'Storico ordini',
    all: 'Tutti',
    undone: 'Non completati',
    completed: 'Completati',
    score: 'Punteggio',
    benutzer: 'Utente',
    chongzhi: 'Ricarica',
    tixian: 'Preleva',
    details: 'Dettagli conto',
    password: 'Gestione password',
    password_jy: 'Password di trasferimento',
    notify: 'Notifica di sistema',
    kefu: 'Servizio clienti',
    bank_card: 'Carta bancaria',
    invite: 'Invita amici',
    team: 'Rapporto del team',
    language: 'Scegli una lingua',
    member: 'Aggiornamento membro',
    balance: 'Saldo conto',
    quit: 'Esci',
    invite_text1: 'Invita amici per guadagnare denaro',
    invite_code: 'Codice di invito',
    invite_btn: 'Link di invito: clicca per copiare',
    copy_s: 'Copiato con successo',
    copy_b: 'Copia fallita',
    grade: 'Livello di appartenenza',
    my_money: 'Saldo conto',
    open_member: 'Unisciti al membro',
    withdraw_num: 'Numero di prelievi',
    day: 'Oggi',
    withdraw_quota: 'Limite di prelievo',
    order_num: 'Quantità di ordini',
    profit_scale: 'Tasso di commissione',
    member_time: 'L’appartenenza è valida per sempre',
    confirm_pay: 'conferma pagamento',
    order_sub: 'Invia Ordine',
    user_info: 'Informazioni utente',
    avatar: 'Avatar',
    username: 'Nome utente',
    set_up: 'Imposta ora',
    revise_name: 'Modifica nome',
    username_hint: 'Per favore, inserisci il tuo nome utente',
    user_account: 'Account utente',
    add_money: 'Ricarica',
    add_money_num: 'Importo ricarica',
    add_money_hint1: '1. L’importo del pagamento deve essere coerente con l’importo dell’ordine, altrimenti non verrà inviato automaticamente',
    add_money_hint2: '2. Se la ricarica e il prelievo non sono stati ricevuti, contatta il tuo superiore o il servizio clienti per risolvere altri problemi.',
    add_money_hint3: 'Per favore, contatta il servizio clienti per le commissioni',
    add_money_record: 'Storico ricariche',
    withdraw_record: 'Storico prelievi',
    withdraw_money: 'Importo prelievo',
    can_withdraw_money: 'Importo di denaro disponibile',
    order_number: 'SN',
    money: 'Importo',
    bank: 'BANCA',
    transaction_flow: 'Numero di transazione',
    already_paid: 'Ho pagato',
    upload_pay_img: 'Carica screenshot pagamento',
    order_details: 'Dettagli conto',
    old_password: 'Vecchia password',
    new_password: 'Nuova password',
    repeat_password: 'Ripeti password',
    enter: 'Per favore, inserisci',
    password_hint: 'Per favore, ricorda la tua password. Se dimentichi la password, contatta il servizio clienti.',
    submit: 'Invia',
    bankCard: 'Collega carta bancaria',
    bank: 'Banca di apertura conto',
    bank_card_num: 'Numero carta bancaria',
    bank_card_name: 'Nome del titolare della carta',
    team: 'Rapporti del team',
    today: 'Oggi',
    yesterday: 'Ieri',
    week: 'Questa settimana',
    scale: 'Proporzione',
    team_people: 'Dimensione del team',
    team_order_scale: 'Commissione per ordini del team',
    open_bank_name: 'Nome del conto',
    phone: 'Telefono',
    user_password: 'Password',
    login: 'Accedi',
    register: 'Registrati',
    password_qr: 'Conferma Password',
    pwd_no_same: 'Le password non corrispondono',
    loading: 'Caricamento',
    freeze: 'Congela',
    pending: 'In sospeso',
    order_hao: 'Numero ordine',
    order_time: 'Tempo di acquisizione ordine',
    price: 'Prezzo unitario',
    order_total_price: 'Totale ordine',
    scale: 'Commissione',
    level: 'Livello di appartenenza',
    level_up: 'Aggiornamento membro',
    pay_way: 'Metodo di prelievo',
    money_min: 'L’importo è troppo basso',
    pay_no: 'Per favore, seleziona un metodo di ricarica',
    // Nuovo
    team_all: 'Tutti',
    team_yi: 'Livello 1',
    team_er: 'Livello 2',
    team_san: 'Livello 3',
    close_order: 'Annulla ordine',
    shouru: 'Entrate',
    zhichu: 'Spese',
    welcome: 'Benvenuto',
    // Nuovo2
    order_kong: 'Il numero dell’ordine non può essere vuoto',
    quer: 'Conferma',
    quxiao: 'Annulla',
    qianbaodi: 'Indirizzo del portafoglio',
    xingming: 'Nome',
    bank_chong: 'Non può essere collegato di nuovo',
    introduce: 'Profilo dell’azienda',
    platform: 'Regole della piattaforma',
    tixianzhang: 'Conto per prelievi',
    xuanze: 'Per favore, scegli',
    xiayiye: 'Clicca per caricare la pagina successiva',
	todays_profits: "Verrà aggiornato quotidianamente. Solo i profitti di oggi sono mostrati qui",  
	Every_rating: "Il profitto di ogni valutazione verrà aggiunto al saldo totale delle attività",  
	Business_Hours: "Ore di attività",  
	further_assistance: "Per ulteriori informazioni, contattare il Servizio Clienti",  
	Change_login_password: "Cambia password di accesso",  
	Change_payment_password: "Cambia password di pagamento",  
	personal_info: "Informazioni personali",  
	Credibility: "Valore di credibilità",  
	daily_earnings: "Il sistema aggiorna automaticamente gli utili giornalieri",  
	profit: "profitto",  
	VIP_Level: "Livello VIP",  
	Mission_Deposit: "Deposito della missione",  
	ordersday: "gli ordini possono essere ricevuti al giorno",  
	Revenueorder: "Entrate per ordine",  
	Profile_Image: "Immagine del profilo",  
	Important_Notice: "Avviso importante",
	welcomehome:"Tutte le informazioni sulle transazioni di questa piattaforma sono soggette a controllo e protezione giuridica. Se avete domande, consultate il servizio clienti online. Grazie per la collaborazione."
}