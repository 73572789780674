module.exports = {
    banner1_text:'A vida é muito curta para passar tempo com pessoas que tiram a felicidade de você. Se alguém quer você em sua vida, eles vão fazer espaço para você.',
    leistungsumfang:'Alcance de serviços',
    leistungsumfang_text:'Atualmente atendendo mais de 100.000 clientes e recebendo boas avaliações',
    leistungsumfang_free:'TRANSFERÊNCIA GRATUITA',
    home:'Início',
    about:'Sobre',
    order:'Encomendar',
    record:'Registro de pedidos',
    user:'Meu perfil',
    about_text1:'Produtos de qualidade',
    about_text2:'Mais produtos',
    order_title:'Pedido de hoje',
    zongzichan:'Total de ativos',
    jinridan:'Número de pedidos hoje',
    tiyanjin:'Fundo de experiência',
    jinrishou:'Renda de hoje',
    jintuandui:'Comissão de equipe hoje',
    dianjigou:'Iniciar tarefa',
    adianjigou:'Enviar',
    order_illustrate:'A plataforma não se responsabiliza se o dinheiro for transferido incorretamente para sua conta bancária. O valor mínimo de saque é de 50.000 won.',
    order_record:'Registro de pedidos',
    all:'Todos',
    undone:'Pendentes',
    completed:'Completados',
    score:'Pontuação',
    benutzer:'Usuário',
    chongzhi:'Recarga',
    tixian:'Saque',
    details:'Detalhes da conta',
    password:'Gerenciamento de senha',
    password_jy:'Senha de transferência',
    notify:'Notificação do sistema',
    kefu:'Serviço ao cliente',
    bank_card:'Cartão bancário',
    invite:'Convidar amigos',
    team:'Relatório de equipe',
    language:'Escolher um idioma',
    member:'Upgrade de membro',
    balance:'Saldo da conta',
    quit:'Sair',
    invite_text1:'Convide amigos para ganhar dinheiro',
    invite_code:'Código de convite',
    invite_btn:'Link de convite: clique para copiar',
    copy_s:'Copiado com sucesso',
    copy_b:'Falha ao copiar',
    grade:'Nível de membro',
    my_money:'Saldo da minha conta',
    open_member:'Ingressar na associação',
    withdraw_num:'Número de saques',
    day:'Dia',
    withdraw_quota:'Limite de saque',
    order_num:'Quantidade de pedidos',
    profit_scale:'Taxa de comissão',
    member_time:'A associação é válida para sempre',
    confirm_pay:'Confirmar pagamento',
    order_sub:'Enviar pedido',
    user_info:'Informações do usuário',
    avatar:'Avatar',
    username:'Nome de usuário',
    set_up:'Configurar agora',
    revise_name:'Modificar nome',
    username_hint:'Por favor, insira seu nome de usuário',
    user_account:'Conta de usuário',
    add_money:'Recarregar',
    add_money_num:'Valor da recarga',
    add_money_hint1:'1. O valor do pagamento deve ser consistente com o valor do pedido, caso contrário, não será recebido automaticamente',
    add_money_hint2:'2. Se a recarga e o saque não foram recebidos, por favor, consulte seu superior ou o serviço ao cliente para resolver outros problemas.',
    add_money_hint3:'Para informações sobre taxas, entre em contato com o serviço ao cliente',
    add_money_record:'Registro de recargas',
    withdraw_record:'Registro de saques',
    withdraw_money:'Valor do saque',
    can_withdraw_money:'Valor disponível para saque',
    order_number:'SN',
    money:'Valor',
    bank:'BANCO',
    transaction_flow:'Número de série da transação',
    already_paid:'Eu já paguei',
    upload_pay_img:'Enviar foto de pagamento',
	order_details: 'Detalhes da conta',  
	old_password: 'Senha antiga',  
	new_password: 'Nova senha',  
	repeat_password: 'Repita a senha',  
	enter: 'Por favor, digite',  
	password_hint: 'Por favor, lembre-se de sua senha. Se você esquecer sua senha, entre em contato com o atendimento ao cliente.',  
	submit: 'Enviar',  
	bankCard: 'Cartão bancário vinculado',  
	bank: 'IBAN',  
	bank_card_num: 'Número do cartão bancário',  
	bank_card_name: 'Nome do titular do cartão',  
	team: 'Relatórios da equipe',  
	today: 'Hoje',  
	yesterday: 'Ontem',  
	week: 'Esta semana',  
	scale: 'Proporção',  
	team_people: 'Tamanho da equipe',  
	team_order_scale: 'Comissão de pedidos da equipe',  
	open_bank_name: 'Nome da conta',  
	phone: 'Telefone',  
	user_password: 'Senha',  
	login: 'Entrar',  
	register: 'Registrar',  
	password_qr: 'Confirmar Senha',  
	pwd_no_same: 'As senhas são inconsistentes',  
	loading: 'Carregando',  
	freeze: 'Congelado',  
	pending: 'Pendente',  
	order_hao: 'Número do pedido',  
	order_time: 'Hora de captura do pedido',  
	price: "Preço unitário",  
	order_total_price: 'Total do pedido',  
	scale: 'Comissão',  
	level: 'Nível de membro',  
	level_up: 'Upgrade de membro',  
	pay_way: 'Método de saque',  
	money_min: 'A quantia é muito pequena',  
	pay_no: 'Por favor, selecione um método de recarga',  
	// Novo  
	team_all: 'Todos',  
	team_yi: 'Nível 1',  
	team_er: 'Nível 2',  
	team_san: 'Nível 3',  
	close_order: 'Cancelar pedido',  
	shouru: 'Rendimento',  
	zhichu: 'Despesas',  
	welcome: 'Bem-vindo',  
	// Novo 2  
	order_kong: 'O número do pedido não pode ser vazio',  
	quer: 'Confirmar',  
	quxiao: 'Cancelar',  
	qianbaodi: 'Endereço da carteira',  
	xingming: 'Nome',  
	bank_chong: 'Não pode ser vinculado repetidamente',  
	introduce: 'Perfil da empresa',  
	platform: 'Regras da plataforma',  
	tixianzhang: 'Conta de saque',  
	xuanze: 'Por favor, escolha',  
	xiayiye: 'Clique para carregar a próxima página',  
	todays_profits: "É atualizado diariamente. Apenas os lucros de hoje são mostrados aqui",  
	Every_rating: "O lucro de cada classificação será adicionado ao saldo total de ativos",  
	Business_Hours: "Horas de funcionamento",  
	further_assistance: "Para obter mais ajuda, entre em contato com o atendimento ao cliente",  
	Change_login_password: "Alterar senha de login",  
	Change_payment_password: "Alterar senha de pagamento",  
	personal_info: "Informações pessoais",  
	Credibility: "Valor de credibilidade",  
	daily_earnings: "O sistema atualiza automaticamente os ganhos diários",  
	profit: "lucro",  
	VIP_Level: "Nível VIP",  
	Mission_Deposit: "Depósito de missão",  
	ordersday: "pedidos podem ser recebidos por dia",  
	Revenueorder: "Receita por pedido",  
	Profile_Image: "Imagem de perfil",  
	Important_Notice: "Aviso importante",  
	welcomehome: "Todas as informações de transações desta plataforma estão sujeitas à supervisão e proteção legal. Se você tiver quaisquer dúvidas, consulte o atendimento ao cliente online. Obrigado pela sua cooperação."

  }