module.exports = {
    banner1_text:'Life is too short to spend time with people who suck the happiness out of you. If someone wants you in their life, they’ll make room for you.',
    leistungsumfang:'Leistungsumfang',
    leistungsumfang_text:'Currently serving 100.000+ custormers and receiving good reviews',
    leistungsumfang_free:'FREE TRANSFER',
    home:'首页',
    about:'关于',
    order:'订单',
    record:'订单记录',
    user:'我的',
    about_text1:'quality goods',
    about_text2:'More products',
    order_title:'今日订单',
    zongzichan:'总资产',
    jinridan:'今日订单数',
    tiyanjin:'体验基金',
    jinrishou:'今日收入',
    jintuandui:'今日团队佣金',
    dianjigou:'开始任务',
    adianjigou:'提交',
    order_illustrate:'The platform is not responsible if money is incorrectly transferred to your bank account.The minimum withdrawal amount is 50,000 won.',
    order_record:'订单记录',
    all:'全部',
    undone:'未完成',
    completed:'已完成',
    score:'评分',
    benutzer:'用户',
    chongzhi:'充值',
    tixian:'提现',
    details:'账户明细',
    password:'密码管理',
    password_jy:'交易密码',
    notify:'系统通知',
    kefu:'客服',
    bank_card:'银行卡',
    invite:'邀请好友',
    team:'团队报告',
    language:'选择语言',
    member:'会员升级',
    balance:'账户余额',
    quit:'退出登录',
    invite_text1:'邀好友赚现金',
    invite_code:'邀请码',
    invite_btn:'邀请链接：点击复制',
    copy_s:'复制成功',
    copy_b:'复制失败',
    grade:'会员等级',
    my_money:'我的账户余额',
    open_member:'开通会员',
    withdraw_num:'提现次数',
    day:'天',
    withdraw_quota:'提现额度',
    order_num:'接单数量',
    profit_scale:'佣金比例',
    member_time:'会员永久有效',
    confirm_pay:'确认支付',
    order_sub:'提交订单',
    user_info:'用户信息',
    avatar:'头像',
    username:'用户名',
    set_up:'立即设置',
    revise_name:'修改名称',
    username_hint:'请填写用户名',
    user_account:'用户账号',
    add_money:'充值',
    add_money_num:'充值金额',
    add_money_hint1:'1。付款金额必须与订单金额一致,否则不会自动到达',
    add_money_hint2:'2.如果未收到充值和提现,请咨询您的上级或客服以解决其他问题',
    add_money_hint3:'收费请联系客服',
    add_money_record:'充值记录',
    withdraw_record:'提现记录',
    withdraw_money:'提现金额',
    can_withdraw_money:'可提现金额',
    order_number:'SN',
    money:'金额',
    bank:'BANK',
    transaction_flow:'交易流水号',
    already_paid:'我已付款',
    upload_pay_img:'上传付款截图',
    order_details:'账户明细',
    old_password:'旧密码',
    new_password:'新密码',
    repeat_password:'重复密码',
    enter:'请输入',
    password_hint:'请牢记密码，如忘记密码请联系客服',
    submit:'提交',
    bankCard:'绑定银行卡',
    bank:'开户行',
    bank_card_num:'银行卡号',
    bank_card_name:'持卡人姓名',
    team:'团队报表',
    today:'今天',
    yesterday:'昨天',
    week:'本周',
    scale:'比例',
    team_people:'团队人数',
    team_order_scale:'团队订单佣金',
    open_bank_name:'开户名',
    phone:'手机号',
    user_password:'密码',
    login:'登录',
    register:'注册',
    password_qr:'确认密码',
    pwd_no_same:'密码不一致',
    loading:'加载中',
    freeze:'冻结',
    pending:'待处理',
    order_hao:'订单号',
    order_time:'抢单时间',
    price:"单价",
    order_total_price:'订单总额',
    scale:'佣金',
    level:'会员等级',
    level_up:'会员升级',
    pay_way:'提现方式',
    money_min:'金额太小',
    pay_no:'请选择充值方式',
    // 新加
    team_all:'全部',
    team_yi:'一级',
    team_er:'二级',
    team_san:'三级',
    close_order:'取消订单',
    shouru:'收入',
    zhichu:'支出',
    welcome:'Welcome',
    // 新加2
    order_kong:'订单号不能为空',
    quer:'确认',
    quxiao:'取消',
    qianbaodi:'钱包地址',
    xingming:'姓名',
    bank_chong:'不能重复绑定',
    introduce:'公司介绍',
    platform:'平台规则',
    tixianzhang:'提现账户',
    xuanze:'请选择',
    xiayiye:'点击加载下一页',
	todays_profits: "将每天更新。此处仅显示今日利润",
	Every_rating: "每个评分的利润将添加到总资产余额中",
	Business_Hours: "营业时间",
	further_assistance: "如需进一步帮助，请联系客户服务",
	Change_login_password: "更改登录密码",
	Change_payment_password: "更改支付密码",
	personal_info: "个人信息",
	Credibility: "信誉值",
	daily_earnings: "系统会自动更新每日收益",
	Profit: "利润",
	VIP_Level: "VIP等级",
	Mission_Deposit: "任务存款",
	ordersday: "每日可接收订单数",
	Revenueorder: "每单收入",
	Profile_Image: "个人头像",
	Important_Notice: "重要通知",
	welcomehome:"本平台一切交易信息均受到法律监管及保护，如有疑问请联系在线客服咨询，谢谢合作"
  }