module.exports = {
    banner1_text: 'La vida es demasiado corta para pasarla con personas que te roban la felicidad. Si alguien te quiere en su vida, harán espacio para ti.',
    leistungsumfang: 'Alcance de servicios',
    leistungsumfang_text: 'Actualmente atendiendo a más de 100,000 clientes y recibiendo buenas evaluaciones',
    leistungsumfang_free: 'TRANSFERENCIA GRATUITA',
    home: 'Inicio',
    about: 'Acerca de',
    order: 'Pedido',
    record: 'Registro de pedidos',
    user: 'Mío',
    about_text1: 'Productos de calidad',
    about_text2: 'Más productos',
    order_title: 'Pedido de hoy',
    zongzichan: 'Total de activos',
    jinridan: 'Número de pedidos hoy',
    tiyanjin: 'Fondo de Experiencia',
    jinrishou: 'Ingresos de hoy',
    jintuandui: 'Comisión del equipo hoy',
    dianjigou: 'Iniciar tarea',
    adianjigou: 'Enviar',
    order_illustrate: 'La plataforma no se hace responsable si el dinero se transfere incorrectamente a tu cuenta bancaria. El monto mínimo para retiros es de 50,000 won.',
    order_record: 'Registro de pedidos',
    all: 'Todos',
    undone: 'Pendientes',
    completed: 'Completados',
    score: 'Puntuación',
    benutzer: 'Usuario',
    chongzhi: 'Recarga',
    tixian: 'Retiro',
    details: 'Detalles de la cuenta',
    password: 'Gestión de contraseñas',
    password_jy: 'Contraseña de transferencia',
    notify: 'Notificación del sistema',
    kefu: 'Servicio de atención al cliente',
    bank_card: 'Tarjeta bancaria',
    invite: 'Invitar amigos',
    team: 'Reporte del equipo',
    language: 'Seleccione un idioma',
    member: 'Actualización de miembro',
    balance: 'Saldo de la cuenta',
    quit: 'Cerrar sesión',
    invite_text1: 'Invita amigos y gana dinero',
    invite_code: 'Código de invitación',
    invite_btn: 'Enlace de invitación: haz clic para copiar',
    copy_s: 'Copiado correctamente',
    copy_b: 'Fallo en la copia',
    grade: 'Nivel de membresía',
    my_money: 'Saldo de mi cuenta',
    open_member: 'Unirse a la membresía',
    withdraw_num: 'Número de retiros',
    day: 'Día',
    withdraw_quota: 'Límite de retiros',
    order_num: 'Cantidad de pedidos',
    profit_scale: 'Tasa de comisión',
    member_time: 'La membresía es válida por siempre',
    confirm_pay: 'confirmar pago',
    order_sub: 'Enviar Pedido',
    user_info: 'Información del usuario',
    avatar: 'Avatar',
    username: 'Nombre de usuario',
    set_up: 'Configurar ahora',
    revise_name: 'Modificar nombre',
    username_hint: 'Por favor, ingresa tu nombre de usuario',
    user_account: 'Cuenta de usuario',
    add_money: 'Recarga',
    add_money_num: 'Monto de recarga',
    add_money_hint1: '1. El monto del pago debe coincidir con el monto del pedido, de lo contrario no se acreditará automáticamente',
    add_money_hint2: '2. Si no recibes la recarga o el retiro, por favor consulta a tu superior o el servicio de atención al cliente para resolver otros problemas.',
    add_money_hint3: 'Por favor, contacta el servicio de atención al cliente para las tarifas',
    add_money_record: 'Registro de recargas',
    withdraw_record: 'Registro de retiros',
    withdraw_money: 'Monto de retiro',
    can_withdraw_money: 'Monto disponible para retiro',
    order_number: 'SN',
    money: 'Monto',
	bank: 'BANCO',  
	transaction_flow: 'Número de serie de la transacción',  
	already_paid: 'Ya pagué',  
	upload_pay_img: 'Subir captura de pantalla del pago',  
	order_details: 'Detalles de la cuenta',  
	old_password: 'Contraseña antigua',  
	new_password: 'Nueva contraseña',  
	repeat_password: 'Repetir contraseña',  
	enter: 'Por favor, ingrese',  
	password_hint: 'Recuerde su contraseña. Si se olvida, por favor, contacte el servicio de atención al cliente.',  
	submit: 'Enviar',  
	bankCard: 'Vincular tarjeta bancaria',  
	bank: 'IBAN',  
	bank_card_num: 'Número de tarjeta bancaria',  
	bank_card_name: 'Nombre del tarjetahabiente',  
	team: 'Reportes del equipo',  
	today: 'Hoy',  
	yesterday: 'Ayer',  
	week: 'Esta semana',  
	scale: 'Proporción',  
	team_people: 'Tamaño del equipo',  
	team_order_scale: 'Comisión de pedidos del equipo',  
	open_bank_name: 'Nombre de la cuenta',  
	phone: 'Teléfono',  
	user_password: 'Contraseña',  
	login: 'Iniciar sesión',  
	register: 'Registrarse',  
	password_qr: 'Confirmar contraseña',  
	pwd_no_same: 'Las contraseñas no coinciden',  
	loading: 'Cargando',  
	freeze: 'Congelado',  
	pending: 'Pendiente',  
	order_hao: 'Número de pedido',  
	order_time: 'Hora de captura del pedido',  
	price: "Precio unitario",  
	order_total_price: 'Total del pedido',  
	scale: 'Comisión',  
	level: 'Nivel de membresía',  
	level_up: 'Actualización de miembro',  
	pay_way: 'Método de retiro',  
	money_min: 'La cantidad es demasiado pequeña',  
	pay_no: 'Por favor, seleccione un método de recarga',  
	// 新加  
	team_all: 'Todo',  
	team_yi: 'Nivel 1',  
	team_er: 'Nivel 2',  
	team_san: 'Nivel 3',  
	close_order: 'Cancelar pedido',  
	shouru: 'Ingresos',  
	zhichu: 'Gastos',  
	welcome: 'Bienvenido',  
	// 新加2  
	order_kong: 'El número de pedido no puede estar vacío',  
	quer: 'Confirmar',  
	quxiao: 'Cancelar',  
	qianbaodi: 'Dirección de billetera',  
	xingming: 'Nombre',  
	bank_chong: 'No puede ser vinculado repetidamente',  
	introduce: 'Perfil de la empresa',  
	platform: 'Reglas de la plataforma',  
	tixianzhang: 'Cuenta de retiro',  
	xuanze: 'Por favor, elegir',  
	xiayiye: 'Haga clic para cargar la siguiente página',  
	todays_profits: "Se actualizará diariamente. Aquí solo se muestran las ganancias de hoy",  
	Every_rating: "Las ganancias de cada calificación se sumarán al saldo total de activos",  
	Business_Hours: "Horario de atención",  
	further_assistance: "Para mayor ayuda, por favor contacte el Servicio de Atención al Cliente",  
	Change_login_password: "Cambiar contraseña de inicio de sesión",  
	Change_payment_password: "Cambiar contraseña de pago",  
	personal_info: "Información personal",  
	Credibility: "Valor de credibilidad",  
	daily_earnings: "El sistema actualiza automáticamente las ganancias diarias",  
	profit: "ganancias",  
	VIP_Level: "Nivel VIP",  
	Mission_Deposit: "Depósito de misión",  
	ordersday: "pedidos que pueden ser recibidos por día",  
	Revenueorder: "Ingresos por pedido",  
	Profile_Image: "Imagen de perfil",  
	Important_Notice: "Aviso importante",  
	welcomehome: "Toda la información de transacciones de esta plataforma está sujeta a supervisión legal y protección. Si tiene alguna pregunta, por favor, consulte el servicio de atención al cliente en línea. Gracias por su cooperación."

}