import zh from './lang/zh'
import de from './lang/de'
import jp from './lang/jp'
import es from './lang/es'
import it from './lang/it'
import uk from './lang/uk'
import vi from './lang/yn'
import tr from './lang/tr'
import pt from './lang/pt'
import sp from './lang/sp'
import ne from './lang/ne'
// // 引入英文语言包
import zhCN from 'vant/es/locale/lang/zh-CN';
import deDE from 'vant/lib/locale/lang/de-DE'
import jaJP from 'vant/lib/locale/lang/ja-JP'
import eses from 'vant/lib/locale/lang/de-DE'
import trtr from 'vant/lib/locale/lang/tr-TR'
import itit from 'vant/lib/locale/lang/it-IT'
import ukuk from 'vant/lib/locale/lang/uk-UA'
import vivi from 'vant/lib/locale/lang/vi-VN'
import ptpt from 'vant/lib/locale/lang/pt-BR'
import spsp from 'vant/lib/locale/lang/es-ES'
export default {
    'hy-hy': {...zh,...zhCN},
    'de-de': {...de,...deDE},
    'jp-jp': {...jp,...jaJP},
	'es-es': {...es,...eses},
	'tr-tr': {...tr,...trtr},
	'it-it': {...it,...itit},
	'uk-uk': {...uk,...ukuk},
	'vi-vi': {...vi,...vivi},
	'pt-pt': {...pt,...ptpt},
	'sp-sp': {...sp,...spsp},
	'ne-ne': {...ne,...ukuk},
}