module.exports = {
    banner1_text: 'Hayat, seni mutsuz eden insanlarla zaman harcamak için çok kısa. Birisi seni hayatında isterse, senin için yer açar.',
    leistungsumfang: 'Hizmet Kapsamı',
    leistungsumfang_text: 'Şu anda 100.000+ müşteriye hizmet veriyoruz ve iyi yorumlar alıyoruz',
    leistungsumfang_free: 'ÜCRETSİZ TRANSFER',
    home: 'Ana Sayfa',
    about: 'Hakkında',
    order: 'Sipariş',
    record: 'Sipariş kaydı',
    user: 'Benim',
    about_text1: 'Kaliteli ürünler',
    about_text2: 'Daha fazla ürün',
    order_title: 'Bugünün siparişi',
    zongzichan: 'Toplam varlık',
    jinridan: 'Bugün sipariş sayısı',
    tiyanjin: 'Deneyim Fondu',
    jinrishou: 'Bugünün geliri',
    jintuandui: 'Bugünün takım komisyonu',
    dianjigou: 'Görev başlat',
    adianjigou: 'Gönder',
    order_illustrate: 'Para yanlışlıkla banka hesabınıza transfer edilirse platform sorumlu değildir. Minimum çekim miktarı 50.000 won\'dur.',
    order_record: 'Sipariş kaydı',
    all: 'Tümü',
    undone: 'Yapılmadı',
    completed: 'Tamamlandı',
    score: 'Puan',
    benutzer: 'Kullanıcı',
    chongzhi: 'Yükleme',
    tixian: 'Çekim',
    details: 'Hesap detayları',
    password: 'Şifre yönetimi',
    password_jy: 'Transfer Şifresi',
    notify: 'Sistem bildirimi',
    kefu: 'Müşteri hizmetleri',
    bank_card: 'Banka kartı',
    invite: 'Arkadaşları davet et',
    team: 'Takım raporu',
    language: 'Bir dil seçin',
    member: 'Üyelik yükseltmesi',
    balance: 'Hesap Bakiyesi',
    quit: 'Çıkış yap',
    invite_text1: 'Arkadaşları davet ederek nakit kazanın',
    invite_code: 'Davet kodu',
    invite_btn: 'Davet linki: kopyalamak için tıklayın',
    copy_s: 'Başarıyla kopyalandı',
    copy_b: 'Kopyalama başarısız',
    grade: 'Üyelik seviyesi',
    my_money: 'Hesap bakiyem',
    open_member: 'Üyeliğe katıl',
    withdraw_num: 'Çekim sayısı',
    day: 'Gökyüzü',
    withdraw_quota: 'Çekim limiti',
    order_num: 'Sipariş miktarı',
    profit_scale: 'Komisyon oranı',
    member_time: 'Üyelik süresi sonsuz',
    confirm_pay: 'Ödemeyi onayla',
    order_sub: 'Siparişi gönder',
    user_info: 'Kullanıcı Bilgileri',
    avatar: 'Profil resmi',
    username: 'Kullanıcı adı',
    set_up: 'Şimdi ayarla',
    revise_name: 'Adı değiştir',
    username_hint: 'Kullanıcı adınızı girin',
    user_account: 'Kullanıcı hesabı',
    add_money: 'Para yükle',
    add_money_num: 'Yükleme miktarı',
    add_money_hint1: '1. Ödeme miktarı sipariş miktarıyla aynı olmalıdır, aksi takdirde otomatik olarak ulaşmayacaktır.',
    add_money_hint2: '2. Yükleme ve çekim alınmazsa, lütfen üst yönetici veya müşteri hizmetleri ile iletişime geçerek diğer sorunları çözün.',
    add_money_hint3: 'Ücretler için lütfen müşteri hizmetleri ile iletişime geçin',
    add_money_record: 'Yükleme kaydı',
    withdraw_record: 'Çekim kaydı',
    withdraw_money: 'Çekim Miktarı',
    can_withdraw_money: 'Kullanılabilir nakit miktarı',
    order_number: 'SN',
    money: 'Miktar',
    bank: 'BANKA',
    transaction_flow: 'İşlem seri numarası',
    already_paid: 'Ödemeyi yaptım',
    upload_pay_img: 'Ödeme ekran görüntüsünü yükleyin',
    order_details: 'Hesap detayları',
    old_password: 'Eski Şifre',
    new_password: 'Yeni Şifre',
    repeat_password: 'Şifreyi tekrarla',
    enter: 'Lütfen girin',
    password_hint: 'Şifrenizi hatırlayın. Şifrenizi unuturursanız, lütfen müşteri hizmetleri ile iletişime geçin.',
    submit: 'Gönder',
    bankCard: 'Banka kartını bağla',
    bank: 'IBAN',
    bank_card_num: 'Banka kartı numarası',
    bank_card_name: 'Kart sahibinin Adı',
    team: 'Takım raporları',
    today: 'Bugün',
    yesterday: 'Dün',
    week: 'Bu hafta',
    scale: 'Oran',
    team_people: 'Takım büyüklüğü',
    team_order_scale: 'Takım sipariş komisyonu',
    open_bank_name: 'Hesap Adı',
    phone: 'Telefon',
    user_password: 'Şifre',
    login: 'Giriş yap',
    register: 'Kayıt ol',
    password_qr: 'Şifreyi Onayla',
    pwd_no_same: 'Şifreler uyuşmuyor',
    loading: 'Yükleniyor',
    freeze: 'Dondur',
    pending: 'Beklemede',
    order_hao: 'Sipariş numarası',
    order_time: 'Sipariş alma zamanı',
    price: 'Birim fiyat',
    order_total_price: 'Sipariş toplamı',
    scale: 'Komisyon',
    level: 'Üyelik seviyesi',
    level_up: 'Üyelik yükseltmesi',
    pay_way: 'Para Çekme Yöntemi',
    money_min: 'Miktar çok küçük',
    pay_no: 'Lütfen bir yükleme yöntemi seçin',
    // 新加
    team_all: 'Tümü',
    team_yi: 'Seviye 1',
    team_er: 'Seviye 2',
    team_san: 'Seviye 3',
    close_order: 'Siparişi iptal et',
    shouru: 'Gelir',
    zhichu: 'Gider',
    welcome: 'Hoş geldiniz',
    // 新加2
    order_kong: 'Sipariş numarası boş olamaz',
    quer: 'Onayla',
    quxiao: 'İptal et',
    qianbaodi: 'Cüzdan adresi',
    xingming: 'Ad',
    bank_chong: 'Yeniden bağlanamaz',
    introduce: 'Şirket Profili',
    platform: 'Platform kuralları',
    tixianzhang: 'Çekim hesabı',
    xuanze: 'Lütfen seçin',
    xiayiye: 'Sonraki sayfayı yüklemek için tıklayın',
	todays_profits: "Gündelik olarak güncellenecektir. Burada sadece bugünün kazançları gösterilir",  
	Every_rating: "Her derecelendirmenin kazancı toplam varlık bakiyesine eklenir",  
	Business_Hours: "İş Saatleri",  
	further_assistance: "Daha fazla yardım için lütfen Müşteri Hizmetlerine başvurun",  
	Change_login_password: "Oturum açma parolasını değiştir",  
	Change_payment_password: "Ödeme parolasını değiştir",  
	personal_info: "Kişisel Bilgiler",  
	Credibility: "Güvenilirlik değeri",  
	daily_earnings: "Sistem günlük kazançları otomatik olarak günceller",  
	profit: "kâr",  
	VIP_Level: "VIP Seviyesi",  
	Mission_Deposit: "Görev Depozito",  
	ordersday: "günde alınabilem sipariş sayısı",  
	Revenueorder: "Sipariş başına gelir",  
	Profile_Image: "Profil Resmi",  
	Important_Notice: "Önemli Bildirim",
	welcomehome:"Bu platformdaki tüm işlem bilgileri yasal denetim ve korumaya tabidir. Herhangi bir sorunuz varsa lütfen çevrimiçi müşteri hizmetine danışin. İşbirliğiniz için teşekkür ederiz."
}