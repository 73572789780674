module.exports = {
banner1_text:'Het leven is te kort om tijd door te brengen met mensen die je geluk wegzuigen. Als iemand je in zijn leven wil hebben, maakt hij/zij plaats voor je.',  
leistungsumfang:'Dienstverleningsomvang',  
leistungsumfang_text:'Momenteel service verleent aan 100.000+ klanten en ontvangt goede beoordelingen',  
leistungsumfang_free:'GRATIS OVERBOEKING',  
home:'Home',  
about:'Over',  
order:'Bestel',  
record:'Besteloverzicht',  
user:'Mijn',  
about_text1:'Kwaliteitsgoederen',  
about_text2:'Meer producten',  
order_title:'Vandaagse bestelling',  
zongzichan:'Totale activa',  
jinridan:'Aantal bestellingen vandaag',  
tiyanjin:'Ervaringsfonds',  
jinrishou:'Inkomsten van vandaag',  
jintuandui:'Teamcommissie vandaag',  
dianjigou:'Start taak',  
adianjigou:'Indienen',  
order_illustrate:'Het platform is niet verantwoordelijk als geld foutief wordt overgemaakt naar je bankrekening. Het minimale uitbetalingsbedrag is 50.000 won.',  
order_record:'Besteloverzicht',  
all:'Alle',  
undone:'Niet voltooid',  
completed:'Voltooid',  
score:'Score',  
benutzer:'Gebruiker',  
chongzhi:'Opladen',  
tixian:'Uitbetaling',  
details:'Rekeningdetails',  
password:'Wachtwoordbeheer',  
password_jy:'Overschrijvingswachtwoord',  
notify:'Systeemmelding',  
kefu:'Klantenservice',  
bank_card:'Bankkaart',  
invite:'Vrienden uitnodigen',  
team:'Teamrapport',  
language:'Kies een taal',  
member:'Lidmaatschapsupgrade',  
balance:'Rekeningstand',  
quit:'Uitloggen',  
invite_text1:'Vrienden uitnodigen om geld verdienen',  
invite_code:'Uitnodigingscode',  
invite_btn:'Uitnodigingslink: klik om te kopiëren',  
copy_s:'Succesvol gekopieerd',  
copy_b:'Kopiëren mislukt',  
grade:'Lidmaatschapniveau',  
my_money:'Mijn rekeningstand',  
open_member:'Lid worden',  
withdraw_num:'Aantal uitbetalingen',  
day:'Dag',  
withdraw_quota:'Uitbetalingslimiet',  
order_num:'Aantal bestellingen',  
profit_scale:'Commission percentage',  
member_time:'Lidmaatschap is voor altijd geldig',  
confirm_pay:'Bevestig betaling',  
order_sub:'Bestelling indienen',  
user_info:'Gebruikersinfo',  
avatar:'Avatar',  
username:'Gebruikersnaam',  
set_up:'Nu instellen',  
revise_name:'Naam wijzigen',  
username_hint:'Gelieve je gebruikersnaam in te voeren',  
user_account:'Gebruikersaccount',  
add_money:'Opladen',  
add_money_num:'Opladingsbedrag',  
add_money_hint1:'1. Het betalingsbedrag moet overeenkomen met het orderbedrag, anders komt het niet automatisch binnen',  
add_money_hint2:'2. Indien de oplading en uitbetaling niet ontvangen worden, gelieve je superior of klantenservice te raadplegen om andere problemen op te lossen.',  
add_money_hint3:'Gelieve klantenservice te raadplegen voor kosten',  
add_money_record:'Opladingsrecord',  
withdraw_record:'Uitbetalingsrecord',  
withdraw_money:'Uitbetalingsbedrag',  
can_withdraw_money:'Beschikbaar uitbetalingsbedrag',  
order_number:'SN',  
money:'Bedrag',  
bank:'BANK',  
transaction_flow:'Transactieserienummer',  
already_paid:'Ik heb betaald',  
upload_pay_img:'Betalingsschermkopie uploaden',  
order_details:'Rekeningdetails',  
old_password:'Oud wachtwoord',  
new_password:'Nieuw wachtwoord',  
repeat_password:'Wachtwoord herhalen',  
enter:'Gelieve in te voeren',  
password_hint:'Gelieve je wachtwoord te onthouden. Indien je je wachtwoord vergeet, gelieve klantenservice te raadplegen.',  
submit:'Indienen',  
bankCard:'Bankkaart binden',  
bank:'IBAN',  
bank_card_num:'Bankkaartnummer',  
bank_card_name:'Kaarthoudersnaam',  
team:'Teamrapporten',  
today:'Vandaag',  
yesterday:'Gisteren',  
week:'Deze week',  
scale:'Proportie',  
team_people:'Teamgrootte',  
team_order_scale:'Teamordercommissie',  
open_bank_name:'Rekeningnaam',  
phone:'Telefoon',  
user_password:'Wachtwoord',  
login:'Inloggen',  
register:'Registreren',  
password_qr:'Bevestig wachtwoord',  
pwd_no_same:'Wachtwoorden zijn niet consistent',  
loading:'Aan het laden',  
freeze:'Bevroren',  
pending:'In behandeling',  
order_hao:'Bestelnummer',  
order_time:'Besteltijd',  
price:"Unit prijs",  
order_total_price:'Totale bestelprijs',  
scale:'Commission',  
level:'Lidmaatschapniveau',  
level_up:'Lidmaatschapsupgrade',  
pay_way:'Uitbetalingswijze',  
money_min:'Het bedrag is te klein',  
pay_no:'Gelieve een opladingswijze te kiezen',  
// 新加  
team_all:'Alle',  
team_yi:'Niveau 1',  
team_er:'Niveau 2',  
team_san:'Niveau 3',  
close_order:'Bestelling annuleren',  
shouru:'Inkomsten',  
zhichu:'Uitgaven',  
welcome:'Welkom',  
// 新加2  
order_kong:'Bestelnummer kan niet leeg zijn',  
quer:'Bevestig',  
quxiao:'Annuleren',  
qianbaodi:'Portemonnee adres',  
xingming:'Naam',  
bank_chong:'Kan niet opnieuw worden gebonden',  
introduce:'Bedrijfsprofiel',  
platform:'Platformregels',  
tixianzhang:'Uitbetalingsrekening',  
xuanze:'Gelieve te kiezen',  
xiayiye:'Klik om de volgende pagina te laden',  
todays_profits:"Dagelijks wordt het bijgewerkt. Hier worden alleen vandaag's winsten weergegeven",  
Every_rating:"De winst van elke beoordeling wordt toegevoegd aan het totale activasaldo",  
Business_Hours:"Kantooruren",  
further_assistance:"Voor verdere hulp, gelieve klantenservice te raadplegen",  
Change_login_password:"Loginwachtwoord wijzigen",  
Change_payment_password:"Betalingswachtwoord wijzigen",  
personal_info:"Persoonlijke info",  
Credibility:"Geloofwaardigheidswaarde",  
daily_earnings:"De dagelijkse winst wordt automatisch door het systeem bijgewerkt",  
profit:"winst",  
VIP_Level:"VIP Niveau",  
Mission_Deposit:"Missiedeposit",  
ordersday:"Bestellingen kunnen worden ontvangen per dag",  
Revenueorder:"Winst per bestelling",  
Profile_Image:"Profielbeeld",  
Important_Notice:"Belangrijke mededeling",  
welcomehome:"Alle transactie-informatie van dit platform valt onder wettelijk toezicht en bescherming. Indien je vragen hebt, gelieve online klantenservice te raadplegen. Dank u voor je medewerking."

  }