module.exports = {
    banner1_text: 'Cuộc đời quá ngắn để lãng phí thời gian với những người làm bạn mất đi niềm vui. Nếu ai đó muốn bạn có mặt trong cuộc sống của họ, họ sẽ tạo chỗ cho bạn.',
    leistungsumfang: 'Phạm vi dịch vụ',
    leistungsumfang_text: 'Hiện tại phục vụ hơn 100.000 khách hàng và nhận được đánh giá tốt',
    leistungsumfang_free: 'CHUYỂN KHOẢN MIỄN PHÍ',
    home: 'Trang chủ',
    about: 'Giới thiệu',
    order: 'Đơn hàng',
    record: 'Lịch sử đơn hàng',
    user: 'Của tôi',
    about_text1: 'Sản phẩm chất lượng',
    about_text2: 'Nhiều sản phẩm hơn',
    order_title: 'Đơn hàng hôm nay',
    zongzichan: 'Tài sản tổng cộng',
    jinridan: 'Số lượng đơn hàng hôm nay',
    tiyanjin: 'Quỹ kinh nghiệm',
    jinrishou: 'Thu nhập hôm nay',
    jintuandui: 'Hoa hồng đội hôm nay',
    dianjigou: 'Bắt đầu nhiệm vụ',
    adianjigou: 'Gửi',
    order_illustrate: 'Nền tảng không chịu trách nhiệm nếu tiền được chuyển nhầm vào tài khoản ngân hàng của bạn. Số tiền rút tối thiểu là 50.000 won.',
    order_record: 'Lịch sử đơn hàng',
    all: 'Tất cả',
    undone: 'Chưa hoàn thành',
    completed: 'Đã hoàn thành',
    score: 'Điểm số',
    benutzer: 'Người dùng',
    chongzhi: 'Nạp tiền',
    tixian: 'Rút tiền',
    details: 'Chi tiết tài khoản',
    password: 'Quản lý mật khẩu',
    password_jy: 'Mật khẩu chuyển khoản',
    notify: 'Thông báo hệ thống',
    kefu: 'Dịch vụ khách hàng',
    bank_card: 'Thẻ ngân hàng',
    invite: 'Mời bạn bè',
    team: 'Báo cáo đội',
    language: 'Chọn ngôn ngữ',
    member: 'Nâng cấp thành viên',
    balance: 'Số dư tài khoản',
    quit: 'Đăng xuất',
    invite_text1: 'Mời bạn bè để kiếm tiền mặt',
    invite_code: 'Mã mời',
    invite_btn: 'Liên kết mời: nhấp để sao chép',
    copy_s: 'Đã sao chép thành công',
    copy_b: 'Sao chép thất bại',
    grade: 'Cấp độ thành viên',
    my_money: 'Số dư tài khoản của tôi',
    open_member: 'Tham gia thành viên',
    withdraw_num: 'Số lần rút tiền',
    day: 'Ngày',
    withdraw_quota: 'Hạn mức rút tiền',
    order_num: 'Số lượng đơn hàng',
    profit_scale: 'Tỷ lệ hoa hồng',
    member_time: 'Thành viên có hiệu lực mãi mãi',
    confirm_pay: 'Xác nhận thanh toán',
    order_sub: 'Gửi đơn hàng',
    user_info: 'Thông tin người dùng',
    avatar: 'Ảnh đại diện',
    username: 'Tên người dùng',
    set_up: 'Cài đặt ngay',
    revise_name: 'Sửa tên',
    username_hint: 'Vui lòng nhập tên người dùng của bạn',
    user_account: 'Tài khoản người dùng',
    add_money: 'Nạp tiền',
    add_money_num: 'Số tiền nạp',
    add_money_hint1: '1. Số tiền thanh toán phải khớp với số tiền đơn hàng, nếu không sẽ không tự động đến',
    add_money_hint2: '2. Nếu tiền nạp và rút không được nhận, vui lòng tham khảo ý kiến cấp trên hoặc dịch vụ khách hàng để giải quyết các vấn đề khác.',
    add_money_hint3: 'Vui lòng liên hệ với dịch vụ khách hàng về phí',
    add_money_record: 'Lịch sử nạp tiền',
    withdraw_record: 'Lịch sử rút tiền',
    withdraw_money: 'Số tiền rút',
    can_withdraw_money: 'Số tiền mặt có sẵn',
    order_number: 'SN',
    money: 'Số tiền',
    bank: 'NGÂN HÀNG',
    transaction_flow: 'Số seri giao dịch',
    already_paid: 'Tôi đã thanh toán',
    upload_pay_img: 'Tải lên ảnh chụp màn hình thanh toán',
    order_details: 'Chi tiết tài khoản',
    old_password: 'Mật khẩu cũ',
    new_password: 'Mật khẩu mới',
    repeat_password: 'Nhập lại mật khẩu',
    enter: 'Vui lòng nhập',
    password_hint: 'Vui lòng nhớ mật khẩu của bạn. Nếu bạn quên mật khẩu, vui lòng liên hệ với dịch vụ khách hàng.',
    submit: 'Gửi',
    bankCard: 'Liên kết thẻ ngân hàng',
    bank: 'Ngân hàng mở tài khoản',
    bank_card_num: 'Số thẻ ngân hàng',
    bank_card_name: 'Tên chủ thẻ',
    team: 'Báo cáo đội',
    today: 'Hôm nay',
    yesterday: 'Hôm qua',
    week: 'Tuần này',
    scale: 'Tỷ lệ',
    team_people: 'Kích thước đội',
    team_order_scale: 'Hoa hồng đơn hàng đội',
    open_bank_name: 'Tên tài khoản',
    phone: 'Điện thoại',
    user_password: 'Mật khẩu',
    login: 'Đăng nhập',
    register: 'Đăng ký',
    password_qr: 'Xác nhận mật khẩu',
    pwd_no_same: 'Mật khẩu không khớp',
    loading: 'Đang tải',
    freeze: 'Đóng băng',
    pending: 'Đang chờ xử lý',
    order_hao: 'Số đơn hàng',
    order_time: 'Thời gian nhận đơn hàng',
    price: 'Giá đơn vị',
    order_total_price: 'Tổng số tiền đơn hàng',
    scale: 'Hoa hồng',
    level: 'Cấp độ thành viên',
    level_up: 'Nâng cấp thành viên',
    pay_way: 'Phương thức nạp tiền',
    money_min: 'Số tiền quá nhỏ',
    pay_no: 'Vui lòng chọn phương thức nạp tiền',
    // 新加
    team_all: 'Tất cả',
    team_yi: 'Cấp 1',
    team_er: 'Cấp 2',
    team_san: 'Cấp 3',
    close_order: 'Hủy đơn hàng',
    shouru: 'Thu nhập',
    zhichu: 'Chi tiêu',
    welcome: 'Chào mừng',
    // 新加2
    order_kong: 'Số đơn hàng không được để trống',
    quer: 'Xác nhận',
    quxiao: 'Hủy',
    qianbaodi: 'Địa chỉ ví',
    xingming: 'Tên',
    bank_chong: 'Không thể liên kết lại',
    introduce: 'Giới thiệu công ty',
    platform: 'Quy định nền tảng',
    tixianzhang: 'Tài khoản rút tiền',
    xuanze: 'Vui lòng chọn',
    xiayiye: 'Nhấp để tải trang tiếp theo',
	todays_profits: "Sẽ được cập nhật hàng ngày. Chỉ hiển thị lợi nhuận của hôm nay",  
	Every_rating: "Lợi nhuận từ mỗi đánh giá sẽ được cộng vào số dư tài sản tổng",  
	Business_Hours: "Giờ làm việc",  
	further_assistance: "Để được hỗ trợ thêm, vui lòng liên hệ với Dịch vụ khách hàng",  
	Change_login_password: "Thay đổi mật khẩu đăng nhập",  
	Change_payment_password: "Thay đổi mật khẩu thanh toán",  
	personal_info: "Thông tin cá nhân",  
	Credibility: "Giá trị độ tin cậy",  
	daily_earnings: "Hệ thống tự động cập nhật thu nhập hàng ngày",  
	profit: "lợi nhuận",  
	VIP_Level: "Cấp độ VIP",  
	Mission_Deposit: "Tiền gửi nhiệm vụ",  
	ordersday: "đơn hàng có thể được nhận mỗi ngày",  
	Revenueorder: "Doanh thu mỗi đơn hàng",  
	Profile_Image: "Hình ảnh hồ sơ",  
	Important_Notice: "Thông báo quan trọng",
	welcomehome:" Tất cả thông tin giao dịch trên nền tảng này đều được giám sát và bảo vệ bởi pháp luật. Nếu có bất kỳ thắc mắc nào, vui lòng liên hệ với dịch vụ khách hàng trực tuyến. Cảm ơn bạn đã hợp tác."
}