module.exports = {
    banner1_text: 'Життя занадто коротке, щоб витрачати час з людьми, які висмоктують з вас щастя. Якщо хтось хоче, щоб ви були в їхньому житті, вони знайдуть для вас місце.',
    leistungsumfang: 'Обсяг послуг',
    leistungsumfang_text: 'На даний момент обслуговуємо понад 100.000 клієнтів і отримуємо хороші відгуки',
    leistungsumfang_free: 'БЕЗКОШТОВНИЙ ПЕРЕКАЗ',
    home: 'Головна',
    about: 'Про нас',
    order: 'Замовлення',
    record: 'Історія замовлень',
    user: 'Мій',
    about_text1: 'Якісні товари',
    about_text2: 'Більше товарів',
    order_title: 'Замовлення сьогодні',
    zongzichan: 'Загальні активи',
    jinridan: 'Кількість замовлень сьогодні',
    tiyanjin: 'Фонд досвіду',
    jinrishou: 'Доходи сьогодні',
    jintuandui: 'Комісія команди сьогодні',
    dianjigou: 'Розпочати завдання',
    adianjigou: 'Відправити',
    order_illustrate: 'Платформа не несе відповідальність, якщо гроші неправильно переведені на ваш банківський рахунок. Мінімальна сума для виведення становить 50.000 вон.',
    order_record: 'Історія замовлень',
    all: 'Всі',
    undone: 'Незавершені',
    completed: 'Завершені',
    score: 'Оцінка',
    benutzer: 'Користувач',
    chongzhi: 'Поповнити',
    tixian: 'Вивести',
    details: 'Дані рахунку',
    password: 'Управління паролями',
    password_jy: 'Пароль для переказу',
    notify: 'Системне повідомлення',
    kefu: 'Обслуговування клієнтів',
    bank_card: 'Банківська картка',
    invite: 'Запросити друзів',
    team: 'Звіт по команді',
    language: 'Виберіть мову',
    member: 'Оновлення членства',
    balance: 'Баланс рахунку',
    quit: 'Вийти',
    invite_text1: 'Запросіть друзів для заробітку грошей',
    invite_code: 'Код запрошення',
    invite_btn: 'Посилання запрошення: натисніть для копіювання',
    copy_s: 'Успішно скопійовано',
    copy_b: 'Не вдалося скопіювати',
    grade: 'Рівень членства',
    my_money: 'Мій баланс рахунку',
    open_member: 'Приєднатися до членства',
    withdraw_num: 'Кількість виведень',
    day: 'Сьогодні',
    withdraw_quota: 'Ліміт виведення',
    order_num: 'Кількість замовлень',
    profit_scale: 'Рівень комісії',
    member_time: 'Членство дійсне безстроково',
    confirm_pay: 'підтвердити платіж',
    order_sub: 'Подати замовлення',
    user_info: 'Інформація про користувача',
    avatar: 'Аватар',
    username: 'Ім’я користувача',
    set_up: 'Налаштувати зараз',
    revise_name: 'Змінити ім’я',
    username_hint: 'Будь ласка, введіть ваше ім’я користувача',
    user_account: 'Обліковий запис користувача',
    add_money: 'Поповнити',
    add_money_num: 'Сума поповнення',
    add_money_hint1: '1. Сума платежу повинна відповідати сумі замовлення, інакше вона не буде автоматично надіслана',
    add_money_hint2: '2. Якщо поповнення та виведення не отримані, будь ласка, зверніться до вашого керівника або служби підтримки для вирішення інших проблем.',
    add_money_hint3: 'Будь ласка, зв’яжіться зі службою підтримки для отримання інформації про збори',
    add_money_record: 'Історія поповнень',
    withdraw_record: 'Історія виведень',
    withdraw_money: 'Сума виведення',
    can_withdraw_money: 'Доступна сума готівки',
    order_number: 'SN',
    money: 'Сума',
    bank: 'БАНК',
    transaction_flow: 'Номер транзакції',
    already_paid: 'Я вже сплатив',
    upload_pay_img: 'Завантажити скріншот платежу',
    order_details: 'Дані рахунку',
    old_password: 'Старий пароль',
    new_password: 'Новий пароль',
    repeat_password: 'Повторити пароль',
    enter: 'Будь ласка, введіть',
    password_hint: 'Будь ласка, запам’ятайте свій пароль. Якщо ви забудете пароль, будь ласка, зверніться до служби підтримки.',
    submit: 'Надіслати',
    bankCard: 'Прив’язати банківську картку',
    bank: 'Банк відкриття рахунку',
    bank_card_num: 'Номер банківської картки',
    bank_card_name: 'Ім’я власника картки',
    team: 'Звіти команди',
    today: 'Сьогодні',
    yesterday: 'Вчора',
    week: 'Цього тижня',
    scale: 'Пропорція',
    team_people: 'Розмір команди',
    team_order_scale: 'Комісія за замовлення команди',
    open_bank_name: 'Ім’я рахунку',
    phone: 'Телефон',
    user_password: 'Пароль',
    login: 'Увійти',
    register: 'Зареєструватися',
    password_qr: 'Підтвердити пароль',
    pwd_no_same: 'Паролі не збігаються',
    loading: 'Завантаження',
    freeze: 'Заморозити',
    pending: 'Очікування',
    order_hao: 'Номер замовлення',
    order_time: 'Час прийому замовлення',
    price: 'Ціна одиниці',
    order_total_price: 'Загальна сума замовлення',
    scale: 'Комісія',
    level: 'Рівень членства',
    level_up: 'Оновлення членства',
    pay_way: 'Спосіб виведення коштів',
    money_min: 'Сума занадто мала',
    pay_no: 'Будь ласка, виберіть метод поповнення',
    // 新加
    team_all: 'Всі',
    team_yi: 'Рівень 1',
    team_er: 'Рівень 2',
    team_san: 'Рівень 3',
    close_order: 'Скасувати замовлення',
    shouru: 'Доходи',
    zhichu: 'Витрати',
    welcome: 'Ласкаво просимо',
    // 新加2
    order_kong: 'Номер замовлення не може бути порожнім',
    quer: 'Підтвердити',
    quxiao: 'Скасувати',
    qianbaodi: 'Адреса гаманця',
    xingming: 'Ім’я',
    bank_chong: 'Не можна прив’язати повторно',
    introduce: 'Профіль компанії',
    platform: 'Правила платформи',
    tixianzhang: 'Рахунок для виведення',
    xuanze: 'Будь ласка, виберіть',
    xiayiye: 'Натисніть, щоб завантажити наступну сторінку',
	todays_profits: "Це буде оновлюватися щодня. Тут показані тільки сьогоднішні прибутки",  
	Every_rating: "Прибутки від кожного рейтингу будуть додати до загального балансу активів",  
	Business_Hours: "Години роботи",  
	further_assistance: "Для отримання додаткового допомоги, будь ласка, зв'язатися з службою обслуговування клієнтів",  
	Change_login_password: "Змінити логін-пароль",  
	Change_payment_password: "Змінити пароль для платежів",  
	personal_info: "Особиста інформація",  
	Credibility: "Стойкості",  
	daily_earnings: "Система автоматично оновлює щоденні доходи",  
	profit: "прибуток",  
	VIP_Level: "Рівень VIP",  
	Mission_Deposit: "Депозит місії",  
	ordersday: "замовлення можуть бути прийняті щодня",  
	Revenueorder: "Доходи від замовлення",  
	Profile_Image: "Профільне зображення",  
	Important_Notice: "Важливе повідомлення"
}